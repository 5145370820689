import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { compose, withFormik, withHooks, withStores } from "enhancers";
import { get, isFunction, isString } from "lodash";
import appStore from "stores/appStore";
import { AppColor } from "theme/app-color";
import Button from "./Button";
import Typography from "./Typography";
import styled from "styled-components/macro";

const getChildElement = (children: any, rest: any) => {
  if (isFunction(children)) {
    return children(rest);
  } else {
    return children;
  }
};

const OkButton = styled(Button)`
  background-color: ${(props) => props.active};
  &:focus {
    background-color: ${(props) => props.active}};
  }
  &:active {
    background-color: ${(props) => props.active};
  }
  &:hover {
    background-color: ${(props) => props.hover};
  }
`;

const ModalComponent = ({
  isShow,
  title,
  children,
  cancelButtonLabel = "Cancel",
  okButtonLabel = "Ok",
  disabled,
  fullWidth,
  maxWidth,
  onOk,
  onCancel,
  onClose,
  onDelete,
  hideFooter,
  okButtonVariant = "text",
  okButtonColor = "text",
  cancelButtonVariant = "text",
  cancelButtonColor = "light",
  okButtonBackground = AppColor["White / White"],
  okButtonBackgroundHover = AppColor["Primary/Light Hover"],
  ...rest
}: any) => (
  <Dialog
    open={isShow}
    onClose={disabled ? undefined : onClose}
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    {...rest}
  >
    <DialogTitle>
      <Typography variant="h4" color={AppColor["Text/Black"]}>
        {title}
      </Typography>
    </DialogTitle>
    <DialogContent>
      {isString(children) ? (
        <DialogContentText>{children}</DialogContentText>
      ) : (
        getChildElement(children, rest)
      )}
    </DialogContent>
    {!hideFooter ? (
      <DialogActions>
        {onCancel && (
          <Button
            variant={cancelButtonVariant}
            onClick={onCancel}
            size="small"
            disabled={disabled}
            style={{
              marginRight: 16,
              border: `1px solid ${cancelButtonColor}`,
              color: cancelButtonColor,
            }}
          >
            {cancelButtonLabel}
          </Button>
        )}
        {onOk && (
          <OkButton
            variant={okButtonVariant}
            onClick={() => onOk(rest)}
            style={{
              color: determineColor(okButtonColor, okButtonLabel),
              border: `1px solid ${okButtonColor}`,
            }}
            size="small"
            disabled={disabled}
            active={okButtonVariant === "outlined" ? okButtonBackground : null}
            hover={
              okButtonVariant === "outlined" ? okButtonBackgroundHover : null
            }
          >
            {okButtonLabel}
          </OkButton>
        )}
      </DialogActions>
    ) : null}
  </Dialog>
);

const enhancer = compose(
  withStores((stores: any, props: any) =>
    props.storeName ? get(stores, props.storeName) : {}
  ),
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { storeName, dispatch, ...rest } = props;
    const { useEffect } = hooks;
    useEffect(() => {
      if (!rest.isShow) {
        rest.resetForm();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest.isShow, rest.resetForm]);

    return rest;
  })
);
const determineColor = (okButtonColor: string, okButtonLabel: string) => {
  if (okButtonColor) return okButtonColor;

  const redLabels = ["ลบ", "ลบข้อมูล", "ปฏิเสธและแจ้งเหตุผล", "ออกจากระบบ"];
  const blueLabels = ["ยืนยันการจองคิว", "ยืนยัน", "ปิด"];

  if (redLabels.includes(okButtonLabel)) return "#F34336";
  if (blueLabels.includes(okButtonLabel)) return "#2296F3";

  return "primary";
};

const Modal = enhancer(ModalComponent);

Modal.open = appStore.openMainModal;
Modal.close = appStore.closeMainModal;
Modal.alert = appStore.openAlertModal;
Modal.confirm = appStore.openConfirmModal;

export default Modal;
