import { ApolloError, gql } from "@apollo/client";
import { compose, withHooks, withStores, withTranslation } from "enhancers";
import paths from "routes/paths";

import appStore from "stores/appStore";

import {
  Redirect,
  Route,
  Switch,
  Notification,
  Icon,
  Box,
  Typography,
  Modal,
} from "components";
import { BlankLayout, MenuLayout } from "layouts";

import AdminEditPage from "pages/main/admins/edit";
import AdminIndexPage from "pages/main/admins/index";
import AdminNewPage from "pages/main/admins/new";

import RoleEditPage from "pages/main/roles/edit";
import RoleIndexPage from "pages/main/roles/index";
import RoleNewPage from "pages/main/roles/new";

import SettingsIndexPage from "pages/main/settings";

import { getRefreshToken, getToken, removeToken } from "api";
import LanguagesDropdown from "components/common/LanguagesDropdown";
import { LoginPage } from "pages/main/authentication/LoginPage";
import { SetupPasswordPage } from "pages/main/authentication/SetupPassword";
import { ResetPasswordPage } from "pages/main/authentication/ResetPassword";

import { useMsal } from "@azure/msal-react";
import { PERMISSIONS } from "constants/enums/permissions";
import { ReportPage } from "pages/main/report";

import EmployeeIndexPage from "pages/main/employee/index";
import EmployeeEditPage from "pages/main/employee/edit";

import BudgetIndexPage from "pages/main/budget/index";
import BudgetEditPage from "pages/main/budget/edit";

import RequestIndexPage from "pages/main/request/index";
import { EventType } from "@azure/msal-browser";
import { setRefreshToken, setToken } from "api";
import { homePath } from "utils/helper";
import useCallback from "react";
import { AppColor } from "theme/app-color";
import { get } from "lodash";

// prettier-ignore
const InitialPages = () => (
  <BlankLayout>
    <LanguagesDropdown />
    <Switch>
      <Route path={paths.signUpPath()} exact component={LoginPage} />

      {/* <Route path={paths.setupPasswordPath()}   exact component={SetupPasswordPage} /> */}

      <Redirect to={paths.signUpPath()} />
    </Switch>
  </BlankLayout>
);

// prettier-ignore
const GuestPages = () => (
  <BlankLayout>
    <LanguagesDropdown />
    <Switch>
      <Route path={paths.signInPath()} exact component={LoginPage} />
      <Route
        path={paths.setupPasswordPath()}
        exact
        component={SetupPasswordPage}
      />
      <Route
        path={paths.resetPasswordPath()}
        exact
        component={ResetPasswordPage}
      />

      <Redirect to={paths.signInPath()} />
    </Switch>
  </BlankLayout>
);

// prettier-ignore
const MainPages = (props: any) => (
  <Switch>
    <Route
      path={paths.adminNewPath()}
      exact
      layout={MenuLayout}
      component={AdminNewPage}
      permittedRoles={["ADMIN_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.adminsPath()}
      exact
      layout={MenuLayout}
      component={AdminIndexPage}
      permittedRoles={["ADMIN_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.adminEditPath(":id")}
      exact
      layout={MenuLayout}
      component={AdminEditPage}
      permittedRoles={["ADMIN_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.rolesPath()}
      exact
      layout={MenuLayout}
      component={RoleIndexPage}
      permittedRoles={["PERMISSION_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.roleNewPath()}
      exact
      layout={MenuLayout}
      component={RoleNewPage}
      permittedRoles={["PERMISSION_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.roleEditPath(":id")}
      exact
      layout={MenuLayout}
      component={RoleEditPage}
      permittedRoles={["PERMISSION_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.settingsPath()}
      exact
      layout={MenuLayout}
      component={SettingsIndexPage}
      permittedRoles={["SETTING_MANAGEMENT_READ"]}
    />
    <Route
      path={paths.reportPath()}
      exact
      layout={MenuLayout}
      component={ReportPage}
      permittedRoles={[PERMISSIONS.REPORT_MANAGEMENT_READ]}
    />
    <Route
      path={paths.employeePath()}
      exact
      layout={MenuLayout}
      component={EmployeeIndexPage}
      permittedRoles={[]}
    />
    <Route
      path={paths.employeeEditPath(":id")}
      exact
      layout={MenuLayout}
      component={EmployeeEditPage}
      permittedRoles={[]}
    />
    <Route
      path={paths.budgetPath()}
      exact
      layout={MenuLayout}
      component={BudgetIndexPage}
      permittedRoles={[]}
    />
    <Route
      path={paths.budgetEditPath(":id")}
      exact
      layout={MenuLayout}
      component={BudgetEditPage}
      permittedRoles={[]}
    />
    <Route
      path={paths.requestPath()}
      exact
      layout={MenuLayout}
      component={RequestIndexPage}
      permittedRoles={[]}
    />
    <Redirect
      withPermissions
      to={homePath(props.currentUser?.role?.permissions)}
    />
  </Switch>
);

interface RoutesProps {
  currentUser: object;
  initialized: boolean;
  hasFirstAdmin: boolean;
  isAuthorized: boolean;
  isAdmin: boolean;
}

// prettier-ignore
const Routes = (props: RoutesProps) => {
  if (!props.initialized) {
    return null;
  } else if (!props.hasFirstAdmin) {
    return <InitialPages />;
  } else if (!props.isAuthorized) {
    return <GuestPages />;
  } else {
    return <MainPages currentUser={props.currentUser} />;
  }
};

export const API = {
  GET_APP_INFO: gql`
    query GET_APP_INFO {
      info {
        hasFirstAdmin
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentBackofficeUser {
        id
        firstName
        lastName
        ownerType
        email
        admin {
          id
          code
          firstName
          lastName
        }
        role {
          title
          permissions
        }
      }
    }
  `,
  SIGN_IN_WITH_AZURE_AD: gql`
    mutation SIGN_IN_WITH_AZURE_AD($email: String!) {
      signInWithAzureAd(email: $email) {
        currentUser {
          id
          email
          authenticationToken
          role {
            permissions
          }
        }
        accessToken
        refreshToken
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: { appStore: { currentUser: any } }) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "api" }),
  withHooks((props: any, hooks: any) => {
    const { currentUser, t } = props;
    const {
      useMemo,
      useEffect,
      useLazyQuery,
      useState,
      useMutation,
      useCallback,
    } = hooks;
    const { accounts, instance } = useMsal();
    const [isReady, setIsReady] = useState(false);
    const [isMsalLoading, setIsMsalLoading] = useState(true);

    const [
      fetchAppInfo,
      { loading: appInfoLoading, data: appInfoData },
    ] = useLazyQuery(API.GET_APP_INFO);
    const [
      fetchCurrentUser,
      { loading, error: currentUserError, data: currentUserData },
    ] = useLazyQuery(API.GET_CURRENT_USER, {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        appStore.setCurrentUser(data.getCurrentBackofficeUser);
        setIsReady(true);
      },
      skipSetError: true,
      onError: (error: ApolloError) => {
        console.log("GET CURRENT USER ERROR : ", error);
        appStore.setCurrentUser(null);
        setIsReady(false);
        removeToken();
      },
    });

    const errorModal = useCallback(
      (message: string) => {
        // show modal on error when signin via azure
        // @ts-ignore
        return Modal.alert({
          className: "ErrorModal",
          title: t(".modal500Title"),
          children: message,
          okButtonLabel: t(".modal500OkButtonLabel"),
          onOk: async ({ ...props }) => {
            sessionStorage.clear();
            window.location.reload();
            // @ts-ignore
            props.close();
          },
          okButtonVariant: "contained",
        });
      },
      [t]
    );

    const [signInWithAzureAd] = useMutation(API.SIGN_IN_WITH_AZURE_AD, {
      onCompleted: (data: any) => {
        console.log("signin via azure Ad => complete : ", data);
        const {
          accessToken,
          refreshToken,
          currentUser,
        } = data.signInWithAzureAd;
        appStore.setCurrentUser(currentUser);
        setToken(accessToken);
        setRefreshToken(refreshToken);
        if (accessToken && refreshToken) {
          fetchCurrentUser();
          fetchAppInfo();
        }
        setIsMsalLoading(false);
      },
      skipSetError: true,
      onError: (error: any) => {
        console.log("signin via azure Ad => error : ", error);

        const message =
          error?.graphQLErrors[0].extensions.extensions.originalError[0]
            .message;
        if (message) {
          errorModal(t(message));
        }
        setIsMsalLoading(false);
      },
    });

    const token = getToken();
    const refreshToken = getRefreshToken();

    useEffect(() => {
      if (token && refreshToken) {
        fetchCurrentUser();
      } else {
        setIsReady(true);
      }
    }, [fetchCurrentUser, token, refreshToken]);
    useEffect(() => {
      fetchAppInfo();
    }, [fetchAppInfo]);

    const pageLoading = useMemo(
      () => loading || isMsalLoading || appInfoLoading,
      [isMsalLoading, loading, appInfoLoading]
    );

    const initialized = !pageLoading && isReady;
    const hasFirstAdmin = !pageLoading && !!appInfoData;
    const isAuthorized = !!(!currentUserError && currentUserData);

    const ownerType = useMemo(() => {
      if (loading || currentUserError) {
        return null;
      }
      return currentUserData?.getCurrentBackofficeUser?.ownerType;
    }, [loading, currentUserData, currentUserError]);

    useEffect(() => {
      const $splashScreen = document.getElementById("splash-screen");
      if ($splashScreen) {
        const display = initialized ? "none" : "";
        $splashScreen.style.display = display;
      }
    }, [initialized]);

    useEffect(() => {
      appStore.setHasFirstAdmin(hasFirstAdmin);
    }, [hasFirstAdmin]);

    useEffect(() => {
      instance
        .handleRedirectPromise()
        .then(async () => {
          const account = accounts[0];
          const currentToken = getToken();

          if (account && !currentToken) {
            setIsReady(false);
            await signInWithAzureAd({
              variables: {
                email: account.idTokenClaims?.preferred_username,
              },
            });
          } else {
            setIsMsalLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, [accounts, instance, signInWithAzureAd]);

    const isAdmin = ownerType === "Admin";

    return {
      currentUser,
      initialized,
      isAuthorized,
      hasFirstAdmin,
      isAdmin,
    };
  })
);

export default enhancer(Routes);
