import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import { Yup, gql, homePath, paths } from "utils/helper";

import {
  Box,
  Button,
  Field,
  Form,
  Modal,
  TextField,
  Typography,
} from "components";
import { TFunction } from "i18next";
import { useEffect } from "react";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { ReactComponent as BpuLogo } from "assets/image/bpu_logo.svg";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

interface SetupPasswordProps {
  isSetupDisabled: boolean;
  t: TFunction;
  email: string;
}

const SetupPasswordPageComponent = (props: SetupPasswordProps) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    minWidth={357}
    width="100%"
    height={900}
    bgcolor={AppColor["Background/Light Grey"]}
  >
    <Box width={520} bgcolor={AppColor["White / White"]} borderRadius="8px">
      <Container>
        <BpuLogo />
        <Box
          style={{ marginBottom: 24, marginTop: 24 }}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Typography variant="h4">{props.t(".title")}</Typography>
          <Typography variant="h4">{props.email}</Typography>
        </Box>
        <Form style={{ width: "100%" }}>
          <Field
            fast
            component={TextField}
            name="password"
            type="password"
            label={props.t(".passwordField")}
            fullWidth
            required
          />
          <div style={{ marginTop: 24 }}>
            <Field
              fast
              component={TextField}
              name="confirmPassword"
              type="password"
              label={props.t(".confirmPasswordField")}
              fullWidth
              required
            />
          </div>
          <Box display="flex" flexDirection="column" mt="24px" ml="6px">
            <Typography
              variant="Helper/12"
              color={AppColor["Text Light/Secondary"]}
            >
              {` • ${props.t(".suggestText.1")}`}
            </Typography>
            <Typography
              variant="Helper/12"
              color={AppColor["Text Light/Secondary"]}
            >
              {` • ${props.t(".suggestText.2")}`}
            </Typography>
            <Typography
              variant="Helper/12"
              color={AppColor["Text Light/Secondary"]}
            >
              {` • ${props.t(".suggestText.3")}`}
            </Typography>
            <Typography
              variant="Helper/12"
              color={AppColor["Text Light/Secondary"]}
            >
              {` • ${props.t(".suggestText.4")}`}
            </Typography>
          </Box>
          <div style={{ marginTop: 24, width: "100%" }}>
            <Button disabled={props.isSetupDisabled} type="submit" width="100%">
              {props.t(".setupPassword")}
            </Button>
          </div>
        </Form>
      </Container>
    </Box>
  </Box>
);

const API = {
  BACKOFFICE_USER_SETUP_PASSWORD: gql`
    mutation BACKOFFICE_USER_SETUP_PASSWORD(
      $invitationToken: String!
      $password: String!
    ) {
      backofficeSetupPassword(
        input: { invitationToken: $invitationToken, password: $password }
      )
    }
  `,
  GET_USER_BY_INVITATION_TOKEN: gql`
    query GET_USER_BY_INVITATION_TOKEN($invitationToken: String!) {
      getUserByInvitationToken(input: { invitationToken: $invitationToken }) {
        email
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    hasFirstAdmin: stores.appStore.hasFirstAdmin,
    currentUser: stores.appStore.currentUser,
  })),
  withFormik({
    mapPropsToValues: () => ({
      password: "",
      confirmPassword: "",
    }),
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, ".passwordNotMatch")
        .max(16, ".passwordNotMatch")
        .matches(
          /^\w*[A-z]\w*[0-9]\w*$|^\w*[0-9]\w*[A-z]\w*$/,
          ".passwordNotMatch"
        ),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password")],
        ".wrongConfirmPassword"
      ),
    }),
  }),
  withTranslation({ prefix: "pages.main.authentication.SetupPassword" }),
  withHooks((props: any, hooks: any) => {
    const {
      useState,
      useHandleSubmit,
      useMutation,
      useMemo,
      useUrlParam,
      useLazyQuery,
      useCallback,
    } = hooks;
    const { values, t } = props;
    const { token: invitationToken } = useUrlParam();
    const [email, setEmail] = useState("");
    const [fetchUser] = useLazyQuery(API.GET_USER_BY_INVITATION_TOKEN, {
      onCompleted: (data: any) => {
        setEmail(data.getUserByInvitationToken.email);
      },
      onError: (e: any) => invitationTokenError(e),
    });

    const [backofficeSetupPassword] = useMutation(
      API.BACKOFFICE_USER_SETUP_PASSWORD,
      {
        onCompleted: (data: any) => {
          if (data.backofficeSetupPassword)
            Modal.alert({
              title: t(".modal.title"),
              children: t(".modal.content"),
              okButtonLabel: t(".modal.button"),
              okButtonVariant: "contained",
              onOk: async ({ ...props }) => {
                paths.homePath().push();
                props.close();
              },
              disableBackdropClick: true,
            });
        },
        skipSetError: true,
        onError: (e: any) => invitationTokenError(e),
      }
    );

    const invitationTokenError = useCallback(
      (e: any) => {
        Modal.alert({
          title: t(".modal.error.title"),
          children:
            t(
              e?.graphQLErrors[0]?.extensions.extensions.originalError[0]
                .message
            ) || "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน",
          okButtonLabel: t(".modal.error.button"),
          onOk: async ({ ...props }) => {
            paths.homePath().push();
            props.close();
          },
          disableBackdropClick: true,
        });
      },
      [t]
    );

    const isSetupDisabled = useMemo(
      () => !values.password || !values.confirmPassword,
      [values]
    );

    useHandleSubmit(async (values: { password: string }) => {
      await backofficeSetupPassword({
        variables: {
          invitationToken: invitationToken,
          password: values.password,
        },
      });
    }, []);

    useEffect(() => {
      if (invitationToken) {
        fetchUser({
          variables: {
            invitationToken: invitationToken,
          },
        });
      }
    }, [fetchUser, invitationToken]);

    return {
      isSetupDisabled,
      email,
      t,
    };
  })
);

export const SetupPasswordPage = enhancer(SetupPasswordPageComponent);
