import { fontSize } from "@material-ui/system";
import { AppColor } from "./app-color";
import { Overrides } from "@material-ui/core/styles/overrides";

const overrides: Overrides = {
  // MuiDataGrid: {
  //   root: {
  //     fontFamily: [
  //       "Noto Sans Thai",
  //       "Inter",
  //       "-apple-system",
  //       "BlinkMacSystemFont",
  //       '"Segoe UI"',
  //       "Roboto",
  //       '"Helvetica Neue"',
  //       "Arial",
  //       "sans-serif",
  //       '"Apple Color Emoji"',
  //       '"Segoe UI Emoji"',
  //       '"Segoe UI Symbol"',
  //     ].join(","),
  //   },
  // },
  MuiDivider: {
    root: {
      backgroundColor: "#A1A1A1",
    },
  },
  MuiDialog: {
    paper: {
      padding: 24,
    },
  },
  MuiDialogTitle: {
    root: {
      padding: 0,
      "& .MuiTypography-root": {
        lineHeight: 1.75,
      },
    },
  },
  MuiDialogContent: {
    root: {
      margin: 0,
      padding: 0,
      paddingTop: 16,
      "& .MuiTypography-root": {
        margin: 0,
        lineHeight: 1.25,
      },
    },
  },
  MuiDialogActions: {
    root: {
      padding: 0,
      marginTop: 16,
    },
  },
  MuiCard: {
    root: {
      borderRadius: "6px",
      boxShadow:
        "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
    },
  },
  MuiButton: {
    root: {
      height: 34,
      borderRadius: 4,
      minWidth: 56,
      fontSize: 16,
    },
    contained: {
      backgroundColor: `${AppColor["Primary/Primary Text"]}`,
      border: `1px solid ${AppColor["Primary/Line"]}`,
      color: `${AppColor["White / White"]}`,
      "&:hover": {
        backgroundColor: `${AppColor["Primary/Hover"]}`,
        // opacity: 0.8,
      },
      "&:disabled": {
        border: "none",
      },
      "& path": {
        fill: `${AppColor["White / White"]}`,
      },
    },
    outlined: {
      backgroundColor: `${AppColor["Light /Button Background"]}`,
      border: `1px solid ${AppColor["Primary/Line"]}`,
      color: `${AppColor["Primary/Primary Text"]}`,
      "&:hover": {
        backgroundColor: `${AppColor["Primary/Light Hover"]}`,
        // opacity: 0.8,
      },
    },
  },
  MuiTabs: {
    indicator: {
      backgroundColor: `${AppColor["Primary/Primary"]}`,
    },
  },
  MuiTab: {
    textColorPrimary: { color: `${AppColor["Primary/Primary"]}` },
    textColorSecondary: { backgroundColor: `${AppColor["Primary/Primary"]}` },
  },
  MuiTextField: {
    root: {
      "& .MuiInputBase-root": {
        borderRadius: 8,
        color: `${AppColor["Text/Placeholder"]}`,
        fontSize: 16,
        fontWeight: 500,
        minHeight: "48px",
      },
      "& .MuiInputBase-root.Mui-disabled": {
        color: `${AppColor["Text/Placeholder"]}`,
        background: `${AppColor["Text/Background"]}`,
        opacity: 0.85,
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#A2AAAD",
        },
        "&::-ms-clear, & input::-ms-reveal": {
          display: "none",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: `${AppColor["Primary/Hover"]}`,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: `${AppColor["Primary/Hover"]}`,
        },
        "& ::selection": {
          background: `${AppColor["Primary/Hover"]}`,
          color: `${AppColor["Text/White"]}`,
        },
      },
      "& .MuiOutlinedInput-input": {
        maxWidth: "1200px",
      },
      "& .MuiFormHelperText-root": {
        color: `${AppColor["Other/Warning"]}`,
        lineHeight: "20px",
        margin: "5px 0px 0px 0px",
        fontSize: 14,
      },
      "& .MuiFormHelperText-root.Mui-error": {
        color: `${AppColor["Other/Danger"]}`,
        lineHeight: "20px",
        margin: "5px 0px 0px 0px",
        fontSize: 14,
      },
      "& .MuiOutlinedInput-multiline": {
        padding: "14px 0px",
      },
    },
  },
  MuiCardHeader: {
    action: {
      marginTop: "-4px",
      marginRight: "-4px",
    },
  },
  // MuiPickersDay: {
  //   day: {
  //     fontWeight: "300",
  //   },
  // },
  // MuiPickersYear: {
  //   root: {
  //     height: "64px",
  //   },
  // },
  // MuiPickersCalendar: {
  //   transitionContainer: {
  //     marginTop: "6px",
  //   },
  // },
  // MuiPickersCalendarHeader: {
  //   iconButton: {
  //     backgroundColor: "transparent",
  //     "& > *": {
  //       backgroundColor: "transparent",
  //     },
  //   },
  //   switchHeader: {
  //     marginTop: "2px",
  //     marginBottom: "4px",
  //   },
  // },
  // MuiPickersClock: {
  //   container: {
  //     margin: `32px 0 4px`,
  //   },
  // },
  // MuiPickersClockNumber: {
  //   clockNumber: {
  //     left: `calc(50% - 16px)`,
  //     width: "32px",
  //     height: "32px",
  //   },
  // },
  // MuiPickerDTHeader: {
  //   dateHeader: {
  //     "& h4": {
  //       fontSize: "2.125rem",
  //       fontWeight: 400,
  //     },
  //   },
  //   timeHeader: {
  //     "& h3": {
  //       fontSize: "3rem",
  //       fontWeight: 400,
  //     },
  //   },
  // },
  // MuiPickersTimePicker: {
  //   hourMinuteLabel: {
  //     "& h2": {
  //       fontSize: "3.75rem",
  //       fontWeight: 300,
  //     },
  //   },
  // },
  // MuiPickersToolbar: {
  //   toolbar: {
  //     "& h4": {
  //       fontSize: "2.125rem",
  //       fontWeight: 400,
  //     },
  //   },
  // },
  MuiChip: {
    root: {
      borderRadius: "6px",
    },
  },
  MuiFormLabel: {
    asterisk: {
      color: "#F34336",
      "&$error": {
        color: "#F34336",
      },
    },
  },
};

export default overrides;
