import { findKey } from "lodash";
import { isClient } from "common/helper";
import { cleanEnv, str } from "envalid";

export const envConfig = cleanEnv(process.env, {
  REACT_APP_ENV: str(),
  REACT_APP_VERSION: str({ default: "0.0.0.0" }),
  REACT_APP_DEFAULT_LANG: str({ default: "th" }),
  NODE_ENV: str({ default: "development" }),
});

const config = {
  uat: {
    HOST: "https://uat.backoffice.bam.ldrive.io",
    API_ENDPOINT: "https://uat.backoffice.bam.ldrive.io/graphql",
    API: "https://uat.api.bam.ldrive.io",
    BACKOFFICE_ENDPOINT: "https://uat.backoffice.bam.ldrive.io",
    APP_ENDPOINT: "https://uat.app.bam.ldrive.io",
  },
  staging: {
    HOST: "https://dev.backoffice.bam.ldrive.io",
    API_ENDPOINT: "https://dev.backoffice.bam.ldrive.io/graphql",
    API: "https://dev.api.bam.ldrive.io",
    BACKOFFICE_ENDPOINT: "https://dev.backoffice.bam.ldrive.io",
    APP_ENDPOINT: "https://dev.app.bam.ldrive.io",
  },
  development: {
    HOST: "http://localhost:22202",
    API_ENDPOINT: "http://localhost:22201/graphql",
    API: "http://localhost:22201",
    BACKOFFICE_ENDPOINT: "http://localhost:22202",
    APP_ENDPOINT: "http://localhost:22203",
  },
};

export const getEnvByHost = () =>
  isClient ? findKey(config, { HOST: window.location.origin }) : null;

export const nodeEnv = getEnvByHost() || "development";

console.log("HOST : ", getEnvByHost());

const appEnvConfig = {
  uat: {
    HOST: "https://uat.app.bam.ldrive.io",
    API_ENDPOINT: "https://uat.app.bam.ldrive.io/graphql",
    API: "https://uat.api.bam.ldrive.io",
  },
  staging: {
    HOST: "https://dev.app.bam.ldrive.io",
    API_ENDPOINT: "https://dev.app.bam.ldrive.io/graphql",
    API: "https://dev.api.bam.ldrive.io",
  },
  development: {
    HOST: "http://localhost:22203",
    API_ENDPOINT: "http://localhost:22201/graphql",
    API: "http://localhost:22201",
  },
};

const MOCK_MSAL_CONFIG = {
  uat: {
    REACT_APP_MSAL_CLIENT_ID: "2461d802-bf29-4d60-a8bc-8f4bcc272ed7",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI: "https://uat.backoffice.bam.ldrive.io",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "https://uat.backoffice.bam.ldrive.io",
  },
  staging: {
    REACT_APP_MSAL_CLIENT_ID: "2461d802-bf29-4d60-a8bc-8f4bcc272ed7",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI: "https://dev.backoffice.bam.ldrive.io",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "https://dev.backoffice.bam.ldrive.io",
  },
  development: {
    REACT_APP_MSAL_CLIENT_ID: "2461d802-bf29-4d60-a8bc-8f4bcc272ed7",
    REACT_APP_MSAL_AUTHORITY: "https://login.microsoftonline.com/common",
    REACT_APP_MSAL_REDIRECT_URI: "http://localhost:22202",
    REACT_APP_MSAL_LOGOUT_REDIRECT_URI: "http://localhost:22202",
  },
};

export const appEnv = appEnvConfig[nodeEnv];

const env = {
  ...config[nodeEnv],
  ...MOCK_MSAL_CONFIG[nodeEnv],
  THEME: "bpu", // bam | scg
  TENANT_ID: "bpu",
};

console.log("ENV : ", env);
export default env;
