import { darken, rgba } from "polished";
import React, { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components/macro";
import { RouteChildType, RouteType } from "../types/routes";
import "../vendor/perfect-scrollbar.css";

import { spacing } from "@material-ui/system";

import {
  Chip,
  Collapse,
  ListItem,
  ListItemText,
  Box as MuiBox,
  Drawer as MuiDrawer,
  List as MuiList,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Typography } from "components";

import { ReactComponent as Logo } from "assets/icon/flex_logo.svg";

import {
  // ADMIN_MAIN_MENU,
  // SUPER_ADMIN_MAIN_MENU,
  // HR_MAIN_MENU,
  MAIN_MENU,
} from "routes/menu";

import { gql } from "@apollo/client";
import { compose, withHooks, withTranslation } from "enhancers";
import { nodeEnv } from "env";

import PopMenu from "components/common/PopMenu";
import { AppColor } from "theme/app-color";
import Authorize from "./Authorize";
import { v4 as uuidv4 } from "uuid";
const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${AppColor["Text/Secondary Text"]};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${AppColor["Text/Secondary Text"]};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)<{
  button?: boolean;
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${AppColor["Text/Secondary Text"]};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: flex-start;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${AppColor["Text/Secondary Text"]};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
`;

const BrandChip = styled(Chip)`
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)}px;
    padding-right: ${(props) => props.theme.spacing(1.375)}px;
  }
`;

type CategoryType = {
  activeClassName?: string;
  button?: boolean;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
};

const Category = styled(ListItem)<CategoryType>`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 24px;
    height: 24px;
    opacity: 1;
  }

  &:hover {
    background: ${AppColor["Primary/Primary"]};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${AppColor["Primary/Primary"]};

    span {
      color: ${AppColor["Text/White"]};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)<{
  activeClassName: string;
  component: typeof NavLink;
  exact: boolean;
  to: string;
}>`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${AppColor["Primary/Primary"]};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${AppColor["Text/White"]};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 1;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${AppColor["Text/Secondary Text"]};
  padding: 12px 16px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${AppColor["Text/White"]};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${AppColor["Text/White"]};
  font-size: 12px;
  line-height: 16px;
  display: block;
  padding: 1px;
`;

const SidebarTopBox = styled(Box)`
  display: flex;
  align-items: center;
  background-color: ${AppColor["Text/Secondary Text"]};
`;

type SidebarCategoryPropsType = {
  name: string;
  icon: JSX.Element;
  classes?: string;
  isOpen?: boolean;
  isCollapsable: boolean;
  badge?: string | number;
  activeClassName?: string;
  button: true;
  onClick?: () => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
};

const SidebarCategory: React.FC<SidebarCategoryPropsType> = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) => {
  let categoryIcon;
  if (isOpen) {
    categoryIcon = <CategoryIconMore />;
  } else {
    categoryIcon = <CategoryIconLess />;
  }
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? categoryIcon : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
};

type SidebarLinkPropsType = {
  name: string;
  to: string;
  badge?: string | number;
  icon?: JSX.Element;
};

const SidebarLink: React.FC<SidebarLinkPropsType> = ({
  name,
  to,
  badge,
  icon,
}) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

type SidebarPropsType = {
  classes?: string;
  staticContext: string;
  location: {
    pathname: string;
  };
  routes: Array<RouteType>;
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  isAdmin?: boolean;
  isSuperAdmin?: boolean;
  t: Function;
  onClose?: () => void;
};

const ENV_TAGS: any = {
  production: null,
  uat: null,
  staging: (
    <BrandChip
      label="STG"
      style={{ backgroundColor: "#1bc5bd", color: "#1e293a" }}
    />
  ),
  development: (
    <BrandChip
      label="DEV"
      style={{ backgroundColor: "#ffeb3b", color: "#1e293a" }}
    />
  ),
};
const ENV_TAG: any = ENV_TAGS[nodeEnv];

const Sidebar: React.FC<RouteComponentProps & SidebarPropsType> = ({
  classes,
  staticContext,
  location,
  isAdmin,
  isSuperAdmin,
  t,
  ...rest
}) => {
  type tplotOptions = {
    [key: number]: boolean;
  };

  const sidebarRoutes = MAIN_MENU;

  const initOpenRoutes = (): tplotOptions => {
    /* Open collapse element that matches current url test*/
    const pathName = location.pathname;

    let _routes = {};

    sidebarRoutes.forEach((route: RouteType, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index: number) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  function createCategoryComponent(category: any, t: Function) {
    return (
      <SidebarCategory
        isCollapsable={false}
        name={t(category.id)}
        to={category.path}
        activeClassName="active"
        component={NavLink}
        icon={category.icon} // Add null check here
        button
        badge={category.badge}
      />
    );
  }
  const getCategoryComponent = (category: any, t: Function) => {
    return category.icon ? createCategoryComponent(category, t) : null;
  };
  return (
    <Drawer variant="permanent" {...rest}>
      <SidebarTopBox>
        <Brand component={NavLink} to="/" button>
          {/* <BrandIcon />{" "} */}
          <Box color="white" ml={1}>
            <Typography variant="Header/24">
              {t(".appName")} {ENV_TAG}
            </Typography>
          </Box>
        </Brand>
        {/* <LanguagesPopMenu /> */}
      </SidebarTopBox>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {sidebarRoutes.map((category: RouteType, index) => (
              <Authorize
                key={"Authorize_" + uuidv4()}
                permissions={category.permissions}
              >
                <React.Fragment key={"header_" + uuidv4()}>
                  {category.header ? (
                    <SidebarSection>{t(category.header)}</SidebarSection>
                  ) : null}

                  {category.children && category.icon ? (
                    <React.Fragment key={"children_" + uuidv4()}>
                      <SidebarCategory
                        isOpen={!openRoutes[index]}
                        isCollapsable={true}
                        name={t(category.id)}
                        icon={category.icon}
                        button={true}
                        onClick={() => toggle(index)}
                      />

                      <Collapse
                        in={openRoutes[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {category.children.map(
                          (route: RouteChildType, index: number) => (
                            <SidebarLink
                              key={route.name + "_" + uuidv4()}
                              name={route.name}
                              to={route.path}
                              icon={route.icon}
                              badge={route.badge}
                            />
                          )
                        )}
                      </Collapse>
                    </React.Fragment>
                  ) : (
                    getCategoryComponent(category, t)
                  )}
                </React.Fragment>
              </Authorize>
            ))}
          </Items>
        </List>
      </Scrollbar>
      <SidebarFooter>
        <PopMenu
          SidebarFooterText={SidebarFooterText}
          SidebarFooterSubText={SidebarFooterSubText}
        />
      </SidebarFooter>
    </Drawer>
  );
};

export const API = {
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentBackofficeUser {
        ownerType
        admin {
          isSuperAdmin
        }
      }
    }
  `,
};

const enhancer = compose(
  withTranslation({ prefix: "components.SideBar" }),
  withHooks((props: any, hooks: any) => {
    const { useQuery, useMemo } = hooks;

    const currentUser = useQuery(API.GET_CURRENT_USER);

    const { loading, data, error } = currentUser || {};

    const ownerType = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return data.getCurrentBackofficeUser?.ownerType;
    }, [loading, data, error]);

    const isAdmin = ownerType === "Admin";
    const isSuperAdmin = isAdmin
      ? data.getCurrentBackofficeUser?.admin?.isSuperAdmin
      : null;

    const propsParams = isSuperAdmin
      ? { ...props, isAdmin, isSuperAdmin }
      : { ...props, isAdmin };
    return propsParams;
  })
);

export default enhancer(withRouter(Sidebar));
