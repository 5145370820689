import MuiTypography, { TypographyProps } from "@material-ui/core/Typography";
import { Variant } from "@material-ui/core/styles/createTypography";
import { CSSProperties } from "react";

import { AppColor } from "theme/app-color";
import typography from "theme/typography";

const CustomVariant = {
  "Helper/12": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
  },
  "Body/14": {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
  },
  "Body/16": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "26px",
  },
  "Header/16": {
    fontSize: "16px",
    fontWeight: 800,
    lineHeight: "32px",
  },
  "Header/20": {
    fontSize: "20px",
    fontWeight: 800,
    lineHeight: "32px",
  },
  "Header/24": {
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
  },
  "Header/32": {
    fontSize: "32px",
    fontWeight: 800,
    lineHeight: "52px",
  },
  "Header/48": {
    fontSize: "48px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  "Subtitle/14": {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  "Subtitle/16": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "26px",
  },
};

const customVariantArr = Object.keys(CustomVariant).join(",");

interface CustomTypographyProps
  extends Pick<
    TypographyProps,
    Exclude<keyof TypographyProps, "color" | "variant">
  > {
  color?: keyof typeof AppColor | string;
  variant: Variant | keyof typeof CustomVariant;
}

const Typography = (props: CustomTypographyProps) => {
  const { color, style, variant, ...rest } = props;
  const isHexa = typeof color === "string" && color?.startsWith("#");
  const customColor = isHexa ? color : AppColor[color as keyof typeof AppColor];

  const customVariant =
    customVariantArr.includes(variant) &&
    CustomVariant[variant as keyof typeof CustomVariant];
  const variantStyle: CSSProperties | undefined =
    customVariantArr.includes(variant) && customVariant
      ? {
          fontSize: customVariant.fontSize,
          fontWeight: customVariant.fontWeight,
          lineHeight: customVariant.lineHeight,
          fontFamily: typography.fontFamily,
        }
      : undefined;
  let customRestProps = customColor
    ? {
        ...rest,
        style: { ...style, color: customColor },
      }
    : { ...rest, style };
  if (customVariant)
    customRestProps = {
      ...customRestProps,
      style: { ...customRestProps.style, ...variantStyle },
    };
  return (
    <MuiTypography
      variant={!customVariant ? (variant as Variant) : "inherit"}
      {...customRestProps}
    />
  );
};

export default Typography;
