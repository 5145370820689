import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as downloadIcon } from "assets/icon/download-icon.svg";
import { ReactComponent as FilterIcon } from "assets/icon/filter_outline.svg";
import { ReactComponent as Trash } from "assets/icon/red_trash.svg";
import {
  Box,
  Button,
  DatePicker,
  Field,
  Form,
  Grid,
  Modal,
  Notification,
  Table,
  Typography,
} from "components";
import Select from "components/common/Select";
import { PERMISSIONS } from "constants/enums/permissions";
import { ReportState } from "constants/enums/report-state";
import dayjs from "dayjs";
import {
  compose,
  withAuthorize,
  withFormik,
  withHooks,
  withTranslation,
} from "enhancers";
import { PageContent } from "layouts";
import appStore from "stores/appStore";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { forceDownload, gql, homePath } from "utils/helper";
import SummaryReportModal from "./ SummaryReportModal";
import ReportModal from "./ReportModal";

const FilterCountNotify = styled(Box)`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffa500;
  display: flex;
`;

const ReportComponent = (props: any) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={[
      {
        children: props.t(".report"),
        onClick: () => props.handleOpenReport(),
        variant: "outlined",
        permittedRoles: ["ADMIN_MANAGEMENT_EDIT"],
      },
      {
        children: props.t(".reportSummary"),
        startIcon: <AddIcon />,
        onClick: () => props.handleOpenSummaryReport(),
        variant: "contained",
        permittedRoles: ["ADMIN_MANAGEMENT_EDIT"],
      },
    ]}
    filter={props.filterSection}
  >
    <ReportModal
      isOpen={props.openReportModal}
      handleCloseModal={() => {
        props.setOpenReportModal(false);
      }}
      fetchData={props.fetchData}
    />
    <SummaryReportModal
      isOpen={props.openSummaryReportModal}
      handleCloseModal={() => {
        props.setOpenSummaryReportModal(false);
      }}
      fetchData={props.fetchData}
    />
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        {props.title}
      </Typography>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        autoHeight
      ></Table>
    </Box>
  </PageContent>
);

export const API = {
  FETCH_REPORTS: gql`
    query FETCH_REPORTS($filters: JSON) {
      reports(input: { filters: $filters }) {
        id
        reportName
        reportType
        state
        startDate
        endDate
        createdAt
        excelFileUrl
      }
    }
  `,
  DELETE_REPORTS: gql`
    mutation DELETE_REPORTS($id: String) {
      deleteReport(input: { id: $id })
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withTranslation({
    prefix: "pages.main.reports.index",
  }),
  withHooks((props: any, hooks: any) => {
    const {
      useQuery,
      useMemo,
      useMutation,
      useEffect,
      useCallback,
      useState,
      useHandleSubmit,
    } = hooks;
    const { t, hasPermission, setValues, values } = props;
    const [filterCount, setFilterCount] = useState(0);
    const [openReportModal, setOpenReportModal] = useState(false);
    const [openSummaryReportModal, setOpenSummaryReportModal] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const { loading, data, error, refetch } = useQuery(API.FETCH_REPORTS, {
      fetchPolicy: "network-only",
    });
    const [deleteReport] = useMutation(API.DELETE_REPORTS, {
      onCompleted: () => {
        Notification.success(t(".remove"));
        refetch({
          filters: { ...values, userId: appStore.state.currentUser.id },
        });
      },
    });
    const handleOpenReport = useCallback(() => {
      setOpenReportModal(true);
    });
    const handleOpenSummaryReport = useCallback(() => {
      setOpenSummaryReportModal(true);
    });
    const fetchData = useCallback(() => {
      refetch({
        filters: { ...values, userId: appStore.state.currentUser.id },
      });
    }, [refetch, values]);
    const hasEditPermission = useMemo(
      () => hasPermission([PERMISSIONS.REPORT_MANAGEMENT_EDIT]),
      [hasPermission]
    );
    useHandleSubmit(
      async (values: any) => {
        setFilterCount(Object.values(values).filter(Boolean).length);
        const variables = {
          filters: { ...values, userId: appStore.state.currentUser.id },
        };
        await refetch(variables);
      },
      [refetch]
    );
    useEffect(() => {
      refetch({
        filters: { userId: appStore.state.currentUser.id },
      });
    }, []);
    //poling
    useEffect(() => {
      let count = 1;
      const intervalID = setInterval(() => {
        console.log(count++, "polling");
        // Check if all reports are complete
        const allReportsCompleteOrFail = data?.reports.every(
          (items: any) => items.state === "complete" || items.state === "failed"
        );

        // If all reports are complete, clear the interval
        if (allReportsCompleteOrFail) {
          clearInterval(intervalID);
          return;
        }

        // If not all reports are complete, continue refetching
        refetch({
          filters: { ...values, userId: appStore.state.currentUser.id },
        });
      }, 5000);

      // Cleanup function
      return () => {
        clearInterval(intervalID);
      };
    }, [data?.reports, refetch, values]);

    const title = t(".reportMenu");
    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: t(".home") },
        { path: null, label: title },
      ];
    }, [title, t]);

    const columns = useMemo(
      () => [
        {
          width: 200,
          field: "reportName",
          headerName: t(".columns.reportName") || "",
        },
        {
          width: 300,
          field: "reportType",
          headerName: t(".columns.reportType") || "",
        },
        {
          width: 150,
          field: "startDate",
          headerName: t(".columns.startDate") || "",
          type: "string",
          renderCell: (startDate: any) => {
            return dayjs(startDate.value)
              .locale("th")
              .add(543, "year")
              .format("DD/MM/YYYY");
          },
        },
        {
          width: 150,
          field: "endDate",
          headerName: t(".columns.endDate") || "",
          type: "string",
          renderCell: (endDate: any) => {
            return dayjs(endDate.value)
              .locale("th")
              .add(543, "year")
              .format("DD/MM/YYYY");
          },
        },
        {
          width: 100,
          field: "state",
          headerName: t(".columns.state") || "",
          type: "string",
          renderCell: (state: any) => {
            return getReportStateDisplay(state.value);
          },
        },
        {
          width: 150,
          field: "createdAt",
          headerName: t(".columns.createdAt") || "",
          type: "date",
          renderCell: (createdAt: any) => {
            return dayjs(createdAt.row.createdAt)
              .locale("th")
              .add(543, "year")
              .format("DD/MM/YYYY, HH:mm");
          },
        },
        {
          width: 100,
          headerName: "Actoin",
          filterable: false,
          sortable: false,
          field: "actions",
          type: "actions",
        },
      ],
      [t]
    );
    const downloadFile = useCallback(
      (excelFileUrl: string, reportName: string) => async () => {
        if (reportName && excelFileUrl) {
          const loadFile = async () => {
            try {
              const blob = await fetch(excelFileUrl).then((r) => r.blob());
              const file = new File([blob], reportName, {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });
              if (file) {
                const url = URL.createObjectURL(file);
                forceDownload(url, reportName);
              }
            } catch (e) {
              Notification.error(e);
            }
          };

          loadFile();
        } else {
          alert("Coming Soon!");
        }
      },
      []
    );
    const deleteFile = useCallback(
      (id: string, name: string) => () => {
        Modal.open({
          title: t(".removetTitle"),
          children: (
            <Box
              flex={1}
              mb={5}
              display="flex"
              flexDirection="row"
              style={{ gap: "4px" }}
            >
              <Typography variant="body1">{"รายงาน"}</Typography>
              <Typography variant="body2">{name}</Typography>
              <Typography variant="body1">
                {" จะถูกลบและไม่สามารถกู้คืนได้"}
              </Typography>
            </Box>
          ),
          cancelButtonLabel: t(".removeButtonCancel"),
          okButtonLabel: t(".removeButtonOK"),
          okButtonVariant: "contained",
          cancelButtonVariant: "outlined",
          onOk: async ({ close }: any) => {
            await deleteReport({ variables: { id } });
            await refetch();
            close();
          },
        });
      },
      [deleteReport, refetch, t]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data?.reports.map((report: any) => {
        const {
          id,
          reportName,
          reportType,
          state,
          startDate,
          endDate,
          createdAt,
          excelFileUrl,
        } = report;
        return {
          id: id,
          reportName,
          reportType,
          state,
          startDate,
          endDate,
          createdAt,
          actions:
            !hasEditPermission || state === ReportState.generating
              ? []
              : [
                  {
                    Icon: downloadIcon,
                    onClick: downloadFile(excelFileUrl, reportName),
                    disabled: !(state === ReportState.complete),
                  },
                  {
                    Icon: Trash,
                    onClick: deleteFile(id),
                    disabled: !(
                      state === ReportState.complete ||
                      state === ReportState.failed
                    ),
                  },
                ],
        };
      });
    }, [loading, error, data?.reports, hasEditPermission, downloadFile]);

    const reportMock = useMemo(() => [
      {
        label: "รายงานการเบิกค่ารักษาพยาบาล (รายพนักงาน)",
        value: "รายงานการเบิกค่ารักษาพยาบาล (รายพนักงาน)",
      },
      {
        label: "รายงานการเบิกค่ารักษาพยาบาลตามฝ่าย/สังกัด",
        value: "รายงานการเบิกค่ารักษาพยาบาลตามฝ่าย/สังกัด",
      },
      {
        label: "รายงานเงินช่วยเหลือบุตร (รายพนักงาน)",
        value: "รายงานเงินช่วยเหลือบุตร (รายพนักงาน)",
      },
      {
        label:
          "รายงานเงินช่วยเหลือบุตรที่ยังไม่เกิน 18 ปีบริบูรณ์ (รายพนักงาน)",
        value:
          "รายงานเงินช่วยเหลือบุตรที่ยังไม่เกิน 18 ปีบริบูรณ์ (รายพนักงาน)",
      },
      {
        label: "รายงานประวัติการเบิกเงินช่วยเหลือบุตร",
        value: "รายงานประวัติการเบิกเงินช่วยเหลือบุตร",
      },
      {
        label: "รายงานการเบิกค่าช่วยเหลือการศึกษาบุตร (รายพนักงาน)",
        value: "รายงานการเบิกค่าช่วยเหลือการศึกษาบุตร (รายพนักงาน)",
      },
      {
        label: "รายงานการเบิกค่าช่วยเหลือจัดการงานศพ (3 เท่า) ",
        value: "รายงานการเบิกค่าช่วยเหลือจัดการงานศพ (3 เท่า) ",
      },
      {
        label: "รายงานการเบิกค่าเจ้าภาพงานศพ",
        value: "รายงานการเบิกค่าเจ้าภาพงานศพ",
      },
      {
        label: "รายงานการเบิกค่าอุปกรณ์เคารพศพ",
        value: "รายงานการเบิกค่าอุปกรณ์เคารพศพ",
      },
      {
        label: "สรุปการเบิกค่ารักษาพยาบาลตามฝ่าย/สังกัด",
        value: "สรุปการเบิกค่ารักษาพยาบาลตามฝ่าย/สังกัด",
      },
      {
        label: "สรุปการเบิกค่าเล่าเรียนบุตรตามฝ่าย/สังกัด",
        value: "สรุปการเบิกค่าเล่าเรียนบุตรตามฝ่าย/สังกัด",
      },
      {
        label: "สรุปการเบิกเงินช่วยเหลือบุตรตามฝ่าย/สังกัด",
        value: "สรุปการเบิกเงินช่วยเหลือบุตรตามฝ่าย/สังกัด",
      },
      {
        label: "สรุปรายการเบิกทั้งหมด รายครั้ง",
        value: "สรุปรายการเบิกทั้งหมด รายครั้ง",
      },
      {
        label: "รายงานเงินช่วยเหลือบุตร (เพิ่มขึ้น) ตามฝ่าย/สังกัด",
        value: "รายงานเงินช่วยเหลือบุตร (เพิ่มขึ้น) ตามฝ่าย/สังกัด",
      },
      {
        label: "รายงานเงินช่วยเหลือบุตร (ลดลง) ตามฝ่าย/สังกัด",
        value: "รายงานเงินช่วยเหลือบุตร (ลดลง) ตามฝ่าย/สังกัด",
      },
    ]);
    const stateMock = useMemo(
      () => [
        {
          label: "สำเร็จ",
          value: "complete",
        },
        {
          label: "กำลังสร้าง",
          value: "generating",
        },
        {
          label: "ล้มเหลว",
          value: "failed",
        },
      ],
      []
    );
    const getReportStateDisplay = useCallback((state: string) => {
      switch (state) {
        case "complete":
          return "สำเร็จ";
        case "generating":
          return "กำลังสร้าง";
        case "failed":
          return "ล้มเหลว";
        default:
          return state;
      }
    }, []);
    const clearFilter = useCallback(async () => {
      setValues({
        reportType: "",
        state: "",
        startDate: null,
        endDate: null,
      });
      setFilterCount(0);
    }, [setValues, values]);
    const handleShowFilter = useCallback(() => {
      setShowFilter(!showFilter);
    }, [showFilter]);

    const filterSection = useMemo(
      () => (
        <Box>
          <Form>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <FilterIcon />
                <Typography variant="h6" mx={2}>
                  {t(".filterTitle")}
                </Typography>
                {filterCount > 0 && (
                  <FilterCountNotify>
                    <Typography variant="body1">{filterCount}</Typography>
                  </FilterCountNotify>
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                onClick={handleShowFilter}
              >
                <Typography
                  variant="body1"
                  style={{
                    textDecoration: "underline",
                    color: AppColor["Primary/Primary Text"],
                    cursor: "pointer",
                  }}
                >
                  {showFilter ? t(".hide") : t(".show")}
                </Typography>
              </Box>
            </Box>
            {showFilter && (
              <Box display="flex" flexDirection="column">
                <Grid mt={6}>
                  <Box>
                    <Grid container spacing={7} mb={4}>
                      <Grid item xs={3}>
                        <Field
                          component={Select}
                          name="reportType"
                          type="text"
                          label="ประเภทรายงาน"
                          placeholder="ประเภทรายงาน"
                          options={reportMock}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          component={Select}
                          name="state"
                          type="text"
                          label="สถานะ"
                          placeholder="สถานะ"
                          options={stateMock}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          fast={false}
                          component={DatePicker}
                          name="startDate"
                          label="วันที่ทำรายการสำเร็จตั้งแต่*"
                          placeholder="วันที่ทำรายการสำเร็จตั้งแต่*"
                          fullWidth
                          maxDate={values.endDate}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          fast={false}
                          component={DatePicker}
                          name="endDate"
                          label="จนถึงวันที่"
                          placeholder="จนถึงวันที่"
                          fullWidth
                          minDate={values.startDate}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Box display="flex" flexDirection="row-reverse" mt={4}>
                  <Button width={74} type="submit">
                    {t(".filter")}
                  </Button>
                  <Button
                    width={74}
                    mr={6}
                    onClick={clearFilter}
                    variant="outlined"
                  >
                    {t(".reset")}
                  </Button>
                </Box>
              </Box>
            )}
          </Form>
        </Box>
      ),
      [
        t,
        filterCount,
        handleShowFilter,
        showFilter,
        reportMock,
        stateMock,
        values.endDate,
        values.startDate,
        clearFilter,
      ]
    );

    return {
      title,
      breadcrumbs,
      tableData,
      columns,
      loading,
      values,
      clearFilter,
      openReportModal,
      openSummaryReportModal,
      handleOpenReport,
      handleOpenSummaryReport,
      setOpenReportModal,
      setOpenSummaryReportModal,
      filterSection,
      fetchData,
    };
  })
);

export const ReportPage = enhancer(ReportComponent);
