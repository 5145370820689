import {
  ApolloClient,
  ApolloProvider,
  from,
  gql,
  InMemoryCache,
  Observable,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { createUploadLink } from "apollo-upload-client";
import { Modal } from "components";
import { env } from "configs";
import { t } from "i18next";
import appStore from "stores/appStore";
import PropTypes from "prop-types";
const TOKEN = "AUTHENTICATION_TOKEN";
export const getToken = () => localStorage.getItem(TOKEN);
export const setToken = (token) => localStorage.setItem(TOKEN, token);
export const removeToken = () => localStorage.removeItem(TOKEN);

const REFRESH_TOKEN = "REFRESH_TOKEN";
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);
export const setRefreshToken = (token) =>
  localStorage.setItem(REFRESH_TOKEN, token);
export const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN);

const isRefreshRequest = (operation) => {
  return operation.operationName === "GEN_REFRESH_TOKEN";
};

// Returns accesstoken if opoeration is not a refresh token request
const returnTokenDependingOnOperation = (operation) => {
  if (isRefreshRequest(operation)) {
    return getRefreshToken() || "";
  } else {
    return getToken() || "";
  }
};

const httpLink = createUploadLink({
  uri: env.API_ENDPOINT,
});

const authLink = setContext((operation, { headers }) => {
  const token = returnTokenDependingOnOperation(operation);
  return {
    headers: {
      ...headers,
      // "x-tenant-id": "bpu",
      "x-tenant-id": env.TENANT_ID,
      authorization: token ? `${token}` : "",
    },
  };
});

const handle401Error = async (operation, forward) => {
  if (operation.operationName === "GEN_REFRESH_TOKEN") {
    Modal.alert({
      className: "TokenExpire",
      title: t("client.api.modal401Title"),
      children: t("client.api.modal401Children"),
      okButtonLabel: t("client.api.modal401OkButtonLabel"),
      okButtonVariant: "contained",
      onOk: async ({ close }) => {
        appStore.logout();
      },
      disableBackdropClick: true,
    });
  } else {
    // Retry the failed request
    const observable = new Observable((observer) => {
      (async () => {
        try {
          await refreshToken();

          // Retry the failed request
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          forward(operation).subscribe(subscriber);
        } catch (err) {
          observer.error(err);
        }
      })();
    });
    return observable;
  }
};

const handle403Error = () => {
  Modal.alert({
    title: t("client.api.modal403Title"),
    children: t("client.api.modal403Children"),
    okButtonLabel: t("client.api.modal403OkButtonLabel"),
    okButtonVariant: "contained",
    onOk: async ({ close }) => {
      appStore.logout();
      close();
    },
    disableBackdropClick: true,
  });
};

const handle500Error = (graphQLErrors) => {
  Modal.alert({
    title: t("client.api.modal500Title"),
    children: graphQLErrors[0]?.message
      ? t(graphQLErrors[0]?.message)
      : "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน",
    okButtonLabel: t("client.api.modal500OkButtonLabel"),
    okButtonVariant: "contained",
    onOk: async ({ close }) => {
      close();
    },
    disableBackdropClick: true,
  });
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      switch (graphQLErrors[0]?.extensions.extensions.http.status) {
        case 401:
          handle401Error(operation, forward);
          return;
        case 403:
          handle403Error();
          return;
        case 500:
          handle500Error(graphQLErrors);
          return;
        default:
          return;
      }
    }

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }
);

const GEN_REFRESH_TOKEN = gql`
  mutation GEN_REFRESH_TOKEN {
    refreshBackofficeUserRefreshToken {
      accessToken
      refreshToken
    }
  }
`;
const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  connectToDevTools: true,
});

const refreshToken = async () => {
  try {
    const refreshResolverResponse = await client.mutate({
      mutation: GEN_REFRESH_TOKEN,
    });

    if (refreshResolverResponse.data.refreshBackofficeUserRefreshToken) {
      const refreshData =
        refreshResolverResponse.data?.refreshBackofficeUserRefreshToken;
      const { accessToken, refreshToken } = refreshData || {};
      setToken(accessToken);
      setRefreshToken(refreshToken);
      return accessToken;
    } else {
      throw new Error("Refresh token data is missing");
    }
  } catch (err) {
    localStorage.clear();
    throw err;
  }
};

export const ApiProvider = (props) => (
  <ApolloProvider client={client}>{props.children}</ApolloProvider>
);
ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const resetStore = client.resetStore;
export const clearStore = client.clearStore;

export { gql };

export default client;
