import { compose, withHooks, defaultProps } from "enhancers";
import { useTranslation } from "react-i18next";
import { Cron as ReactJsCron } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import "./index.css";
import { LOCALE } from "./locales";
import { DEFAULT_LOCALE_TH } from "./locales/languages/th";
import { Box, Typography } from "components";
import { AppColor } from "theme/app-color";

const CustomCron = (props: any) => (
  <Box display="flex" flexDirection="column" position="relative">
    <Box
      display="flex"
      style={{
        marginTop: "-5px",
        left: "36px",
        position: "absolute",
        zIndex: 9,
      }}
    >
      <Typography
        variant="caption"
        color={AppColor["Text Light/Secondary"]}
        style={{
          width: "30px",
          backgroundColor: "white",
          textAlign: "center",
          zIndex: 10,
        }}
      >
        {`วันที่`}
        <span style={{ color: AppColor["Other/Danger"] }}>{`*`}</span>
      </Typography>
      <Typography
        variant="caption"
        color={AppColor["Text Light/Secondary"]}
        style={{
          width: "40px",
          backgroundColor: "white",
          textAlign: "center",
          marginLeft: "82px",
          zIndex: 10,
        }}
      >
        {`เดือน`}
        <span style={{ color: AppColor["Other/Danger"] }}>{`*`}</span>
      </Typography>
    </Box>
    <ReactJsCron {...props} />
    <Typography
      variant="caption"
      color={AppColor["Other/Danger"]}
      style={{ marginTop: "-16px", marginLeft: "26px" }}
    >
      {`${props.errorMessage}`}
    </Typography>
  </Box>
);

const enhancer = compose(
  defaultProps({
    customProps: {
      className: "my-project-cron",
      locale: DEFAULT_LOCALE_TH,
      clearButtonProps: { type: "default", danger: false },
      allowedDropdowns: ["month-days", "months", "hours", "minutes"],
      defaultPeriod: "year",
      defaultValue: "@yearly",
      shortcuts: true,
      clockFormat: "24-hour-clock",
      mode: "single",
      leadingZero: false,
      clearButton: false,
    },
  }),
  withHooks((props: any, hooks: any) => {
    const date = new Date();
    const { useCallback, useMemo, useState } = hooks || {};
    const { field, form, disabled, customProps, maxDate, minDate } =
      props || {};
    const { className } = customProps || {};
    const {
      value = `${date.getMinutes()} ${date.getHours()} ${date.getDate()} ${
        date.getMonth() + 1
      } *`,
      name,
    } = field || {};
    const { setFieldValue } = form || {};
    const [errorMessage, setErrorMessage] = useState("");

    const setValue = useCallback(
      (value: string) => {
        setFieldValue(name, value);
      },
      [setFieldValue, name]
    );

    const { i18n } = useTranslation();
    const customClassName = useMemo(() => {
      return `${customProps.className} ${i18n.language} ${className}`;
    }, [i18n.language, customProps.className, className]);

    const locale = useMemo(() => {
      return LOCALE[i18n.language];
    }, [i18n.language]);

    return {
      ...customProps,
      locale,
      value,
      setValue,
      disabled,
      errorMessage,
      className: customClassName,
    };
  })
);

export default enhancer(CustomCron);
